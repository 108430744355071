<p>airport-list works!</p>

{{ airports }}
{{data}}
<ul>
    <li *ngFor="let airport of airports">
        {{ airport.airportName }}
        Test
    </li>
</ul>
