import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common'
import { Airport, DataService } from './data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-airport-list', 
  standalone: true,
  imports: [ CommonModule],
  templateUrl: './airport-list.component.html',
  styleUrl: './airport-list.component.css'
})

export class AirportListComponent implements OnInit  {
  airports: any;
  isLoading: boolean = false;
  data: string | undefined;

  constructor(private http: HttpClient,private dataService: DataService) {}
  
  async ngOnInit() {
    this.http.get<Airport[]>('https://api.marketplace.aero/api/v0.1/airport').subscribe(response => {
      this.airports = response
      console.log(JSON.stringify(response));
    });  
    console.log(this.airports)
    this.data = JSON.stringify(this.airports);
  }
}
