<mat-card>
  <mat-card-header>
    <h1>And relax...</h1>
  </mat-card-header>
  <mat-card-content >
    <div class="container">

   <div>We'll send you updates on your flight. Meanwhile you've got about 90 minutes
     before boarding starts. Why not sit back relax and treat yourself in the airport
     - your holiday has started... </div>

      <div class="center">🛍️ 👗 🍱 🍸</div>


      <mat-form-field [style.width.%]="100">
        <mat-label>Ask me anything...</mat-label>
        <input matInput placeholder="{{amaPlaceholder}}">
      </mat-form-field>

    </div>


  </mat-card-content>
</mat-card>
