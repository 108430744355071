import {Component} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatCard, MatCardContent} from "@angular/material/card";
import {MatFormField, MatFormFieldModule, MatLabel} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {Router, RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-start',
  standalone: true,
  imports: [RouterOutlet, MatInputModule, MatFormFieldModule, MatCard, MatCardContent, MatButton],
  templateUrl: './start.component.html',
  styleUrl: './start.component.css'
})
export class StartComponent {

  constructor(private router: Router ) {
  }
  connectToPassenger() {

    this.router.navigateByUrl('connected').then(value => {
      console.log(value)
    })
  }
}
