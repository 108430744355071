<mat-card>
  <mat-card-content >
    <div class="container">
      <h1>Flight Information ✈️</h1>
      <div>Enter your flight number and mobile number for live updates while you're in the airport.</div>

      <mat-form-field  [style.width.%]="80">
        <mat-label>Flight Number</mat-label>
        <input matInput placeholder="Ex. AA123" value="">
      </mat-form-field>

      <mat-form-field [style.width.%]="80">
        <mat-label>Mobile Number</mat-label>
        <input matInput placeholder="e.g. +44 7770123123">
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="connectToPassenger()">WhatsApp me updates!</button>
    </div>
  </mat-card-content>
</mat-card>
