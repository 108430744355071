import {Component} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {timer} from "rxjs";

@Component({
  selector: 'app-connected',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatCardContent,
    MatFormField,
    MatInput,
    MatLabel,
    MatCardHeader
  ],
  templateUrl: './connected.component.html',
  styleUrl: './connected.component.css'
})
export class ConnectedComponent {

  questions = [
    "Where can I get a coffee?",
    "What's the wait time at security zone?",
    "Where can I get a phone charger?",
    "I need sunglasses, where do I go?",
    "Tell me a fun fact about the airport.",
  ]
  amaPlaceholder = this.questions[Math.floor(Math.random() * this.questions.length)];
  timer5Seconds = timer(1000, 2000);
  subscribe = this.timer5Seconds.subscribe(val => {
    this.amaPlaceholder = this.questions[Math.floor(Math.random() * this.questions.length)];
  });

}
