import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';

export interface Airport {
  id: string;
  airportName: string;
  airportCode: string;
  cityName: string;
  countryName: string;
  description: string;
}

interface SearchResults {
    total: number;
    results: Array<object>;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private apiUrl = 'https://api.marketplace.aero/api/v0.1/airport';

  constructor(private http: HttpClient) {}

  getAllAirports(): any {
    this.http.get<Airport[]>('https://api.marketplace.aero/api/v0.1/airport').subscribe(response => {
        console.log(response)
        console.log(JSON.stringify(response));
        return response;
      });  
  }
}