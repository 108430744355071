import {Routes} from '@angular/router';
import {StartComponent} from "./start/start.component";
import {ConnectedComponent} from "./connected/connected.component";
import {AirportListComponent} from "./airport-list/airport-list.component"
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule } from '@angular/common'

export const routes: Routes = [
  {path: '', redirectTo: '/start', pathMatch: 'full'},
  {path: 'start', component: StartComponent},
  {path: 'connected', component: ConnectedComponent},
  {path: 'airport', component: AirportListComponent}
];